<template>
	<v-row no-gutters>
    <v-overlay :value="overlay" z-index="1">
      <v-progress-circular indeterminate size="44">
      </v-progress-circular><br>
      {{$t('base.wait')}}...
    </v-overlay>
    <v-col cols="12" sm="12" md="6" class="mx-auto mb-6 pt-3 pl-2 pr-2 pr-sm-4">
      <span class="title primary--text">
        {{ `${ $tc('dear',0) } ${ $tc('actor.actor',1) }` }}
      </span>
      <p class="mt-2 text-justify">{{ $t('file_library.documentation_warning') }}</p>
    </v-col>
    <v-col v-for="(item, i) in type" :key="i" cols="12" :md="item.width ? 10 : 6 " :class="item.width ? 'mx-auto':''" class="mb-6">
      <v-row no-gutters>
        <filepreview
          v-if="item.exist"
          :file="item.file"
          :key="i"
          class="mx-auto mx-lg-0 pt-5"
        ></filepreview>
        <v-col cols="12" sm="8" :md="item.exist ? '8':'7'" class="mx-auto pt-3">
          <v-card outlined class="card-outlined darker mx-auto mx-md-0 ml-md-auto" :class="item.exist ? '':'mx-md-auto'" :width="item.width ? item.width : 340" height="200">
            <v-card-title>
              <div class="title primary--text">
                <span class="px-1 white darker">
                  {{ $t('file_library.'+item.file_name) }}
                </span>
              </div>
            </v-card-title>
            <v-card-text class="py-0">
              <v-container class="pa-0">
                <v-row no-gutters>
                  <v-col cols="12" class="py-10 px-4 text-center">
                    <v-file-input small-chips clearable
                      accept="application/pdf,image/png,image/jpeg,image/jpg"
                      :label="$t('file_library.'+item.file_name)"
                      :ref="item.file_name"
                      @change="validateFile($event, item)"
                      @click:clear="clearInput(item)"
                    >
                    </v-file-input>

                    <v-btn save
                      :loading="item.loading"
                      :disabled="!item.pre_loaded"
                      @click="loadFile(item)"
                    >
                      
                      <span v-if="item.exist" class="mr-3">
                        {{$tc('base.change',1)}}
                      </span>
                      <span v-else class="mr-3">
                        {{$tc('load',1)}}
                      </span>
                      <v-icon small>fas fa-save</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props:{
    person_type:{
      type:[String],
      default:null
    },
    subscriber_id:{
      type:[Number],
      default:0
    }
  },
  data() {
    return {
      overlay: false,
      emit_completed: false,
      natural_fields: [
        {
          file_name: 'signed_acceptance_letter',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {}
        },
        {
          file_name: 'dni',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {}
        },
        {
          file_name: 'rif',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {},
        }
      ],
      legal_fields: [
        {
          file_name: 'signed_acceptance_letter',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {}
        },
        {
          file_name: 'legal_representative_dni',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {},
        },
        {
          file_name: 'legal_representative_rif',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {},
        },
        {
          file_name: 'legal_person_rif',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {},
        },
        {
          file_name: 'assembly_minute',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {},
        },
        {
          file_name: 'legal_person_registration_document',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          width: 480,
          file: {},
        }
      ],
      firm_fields: [
        {
          file_name: 'signed_acceptance_letter',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {}
        },
        {
          file_name: 'dni',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {},
        },
        {
          file_name: 'rif',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          file: {},
        },
        {
          file_name: 'legal_person_registration_document',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          width: 480,
          file: {},
        },
        {
          file_name: 'last_registration_modification',
          exist: false,
          loading: false,
          pre_loaded: false,
          temp_file: null,
          width: 480,
          file: {},
        }
      ]
    }
  },
  computed:{
    type () {
      let type = ''
      switch (this.person_type) {
        case 'natural':
          type = this.natural_fields
          break
        case 'legal':
          type = this.legal_fields
          break
        case 'firm':
          type = this.firm_fields
          break
        default:
          type = ''
          break
      }
      return type
    },
    docs_completed () {
      return this.type.findIndex(m => m.exist === false ) != -1 ? false : true
    }
  },
  methods:{
    findFiles () {
      this.overlay = true
      let file_names = []
      Object.values(this.type).forEach(obj => {
        file_names.push(obj.file_name)
      })
      axios.get(this.$store.getters.getSubscriberFilesLibrary(), {
          params: {
            subscriber_id: this.subscriber_id,
            name: file_names
          }
        })
        .then(response => {
          if (response.data.files && response.data.files.data && response.data.files.data.length > 0) {
            Object.values(this.type).forEach(obj => {
              // file_names.push(obj.file_name)
              let tmp = response.data.files.data.filter(file => file.file_name == obj.file_name)[0]
              if (tmp) {
                obj.file = tmp
                obj.exist = true
              }
            })
          }
        })
        .finally(()=>{
          this.overlay = false
        })
    },
    validateFile (e, item) {
      if (e) {
        if (!['image/png','image/jpeg','image/jpg','application/pdf'].includes(e.type)) {
          this.emitNotification('error', 6000, 'error422.file_path_must_type')
          this.clearInput(item)
        } else if (e.size > 4194304) {
          this.emitNotification('error', 6000, 'error422.file_path_max_4096_kb')
          this.clearInput(item)
        } else {
          item.pre_loaded = true
          item.temp_file = e
        }
      }
    },
    clearInput (item) {
      this.$refs[item.file_name][0].reset()
      this.$refs[item.file_name][0].$refs.input.value = ''
      item.pre_loaded = false
    },
    loadFile (item) {
      item.loading = true
      let form_data = new FormData()
      let id = null
      form_data.append('subscriber_id', this.subscriber_id)
      form_data.append('file_name', item.file_name)
      form_data.append('file_path', item.temp_file)
      if (item.exist) {
        id = item.file.id
        form_data.append('_method', 'PATCH')
      }
      axios.post(this.$store.getters.getSubscriberFilesLibrary(id), form_data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.success) {
            this.clearInput(item)
            item.exist = true
            item.file = response.data.success
            this.emitNotification('success', 6000, 'success',
              {
                model: this.$tc('file_library.file',1),
                action: this.$tc('loaded',1)
              }, 1
            )
          } else {
            this.emitNotification('warning', 6000, 'base.something_wrong')
          }
        })
        .finally(() => {
          item.loading = false
        })
    },
    emitNotification (style, timeout, notifText, extra, pos) {
      this.$store.dispatch('pushNotification', {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText,
        notifPos: pos,
        extra: extra
      })
    },
  },
  mounted () {
    this.findFiles()
  },
  watch:{
    docs_completed (val){
      if (val) {
        this.$emit('completed', val)
      }
    }
  }
}
</script>
