import acceptance_letter from "./DialServiceSale.vue"

export default {
  components:{
    dialservicesale: acceptance_letter
  },
  data(){
    return {
      options: {
        page: 1,
        itemsPerPage: 10
      },
      filter: '',
      contracts:[],
      totalValues: 0,
      tableLoading: false,
      expand: false,
      step : 'tab-1',
      unwatch: '',
      policies:{
        show:false,
      }
    };
  },
  watch:{
    options: {
      handler () {
        this.getContracts(this.expand);
      },
      deep: true,
    },
    expand () {
      if (!this.expand) {
        this.filter = ''
        this.$validator.reset()
        this.options.page = 1
        this.getContracts(false)
      }
    },
    step(){
      this.PoliciesServiceSale();
      this.getContracts(this.expand)
    }
  },
  methods:{
    expandIt(){
      this.expand = true
      let obj = this
      setTimeout(function() {
        obj.$refs.search.focus()
      }, 400)
    },
    search(){
      this.$validator.validate('filter', this.filter)
        .then(valid => {
          if (valid) {
            this.options.page = 1
            this.getContracts(this.expand)
          }
        })
        .catch(error => {
            // console.log(error)
        })
    },
    async getContracts(is_filter){
      this.contracts = []
      this.tableLoading = true
      let ops = {
        params: {
          'paginate': this.options.itemsPerPage, 
          'page': this.options.page,
          'step': this.step[this.step.length-1]
        }
      }
      if (this.options.sortDesc.length > 0) {
        ops.params.dirDesc = this.options.sortDesc[0]
      }
      if (this.options.sortBy.length > 0) {
        ops.params.orderBy = this.options.sortBy[0]
      }
      if (is_filter) {
        let validation = await this.$validator.validate('filter', this.filter)
        if (validation) {
          ops.params.filter = this.filter
        } else {
          this.tableLoading = false
          this.options.page = 1
          return false
        }
      }
      axios.get(this.$store.getters.getAcceptanceLetter(), ops)
        .then(response => {
          this.contracts = response.data.contracts.data
          this.totalValues = response.data.contracts.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    PoliciesServiceSale(){
      let policies = [
        'index_acceptanceLetter',
        'index_subscriberFileLibrary',
      ];
      if (this.step == 'tab-1') {
        const policiesTab1=[
          'invoiceQueries_clientDocument',
          'index_conceptMotive',
          'getClientDocument_clientDocument',
          'update_clientDocument',
          'makeClientDocumentPDF_clientDocument',
          'store_subscriberFileLibrary',
          'askingEmail_contract',
        ];
        policies = [...policies,...policiesTab1];
      }
      if (this.step == 'tab-2') {
        const policiesTab2=[
          'store_acceptanceLetter',
          'send_acceptanceLetter'
        ];
        policies = [...policies,...policiesTab2];
      }
      if (this.step == 'tab-3') {
        const policiesTab3=[
          'show_contract',
          'store_subscriberFileLibrary',
          'changeProspectCondition_subscriber'
        ];
        policies = [...policies,...policiesTab3];
      }
      this.policies.show = this.getPermissionGroup({
        permission:policies,
        mode:'all'
      });
    },
  },
  computed:{
    headers(){
      let headers = [
        {
          text: this.$tc('base.code',3),
          align: 'left',
          value: 'id',
          width: 86
        },
        {
          text: this.$t('company.identification'),
          align: 'left',
          value: 'doc',
          width: 140
        },
        { 
          text: this.$t('subscribers.legal_denomination'), 
          value: 'legal_denomination'
        },
      ];
      if (this.policies.show) {
        headers.push({ 
          text: this.$t('action'), 
          value: 'action', 
          sortable: false ,
          align: 'right',
          width: 140
        });
      }
      return headers;
    } 
  },
  created(){
    this.unwatch = this.$store.watch(() => this.$store.getters.getConfirmationFinished, val => {
      if (val) {
        if (this.$store.getters.getConfirmationTemplate == 'send_acceptance_letter') {
          this.$refs.dialservicesale.dialog = false;
        }
        this.getContracts();
      }
    })
  },
  mounted(){
    this.PoliciesServiceSale();
  },
  beforeDestroy () {
    this.unwatch();
  }
}