import quotation from "@/components/paymentNote/paymentNote.vue"
import DocumentationLoad from "./DocumentationLoad.vue"

export default {
  components:{
    quotation: quotation,
    DocumentationLoad
  },
  data(){
    return {
      letter: {
        contract_id: null,
        considerations: ''
      },
      dialog: false,
      e1: 1,
      edit2: false,
      edit3: false,
      contract: {},
      loading: false,
      loadingNext: false,
      loadingLetter: false,
      service_proposal: null,
      letter_file_exist: false,
      proposal_file_exist: false,
      quotation_file_exist: false,
      quotation_valid: true,
      file: null,// Service Proposal
      file2: null,// Quotation
      file3: null,// Acceptance Letter
      show_btn: true,
      doc_id: '',
      // First Email Data (Service Proposal & Price Quotation)
      mail_title: 'Propuesta de Servicio y Cotización',
      mail_content: '<p style="text-align:justify;">Se Adjuntan la <b>Propuesta Comercial</b> y <b>Cotización</b> para la instalación del Servicio solicitado.<br>Quedamos a la orden para cualquier inquietud o aclaratoria, y de ser favorable su respuesta agradecemos nos informe con respuesta a este correo electrónico.</p>',
      // mail_content: 'Saludos. Se adjuntan los siguientes documentos: Propuesta de Servicio y Documento de Cotización. Si su respuesta es favorable agradecemos nos informe mediante correo electrónico.',
      contract_types_to_mail_proposal: ['tech', 'admin'],
      contract_types_to_mail_letter: ['legal'],
      acceptance_letter_info: {
        letter_id: 0,
        file_id: 0
      },
      person_type: null,
      doc_completed: false
    }
  },
  methods:{
    open (contract, mode) {
      switch (mode) {
        case '1':
          this.edit2 = false
          this.edit3 = false
          this.loadingNext = true;
          this.e1 = 1
          this.quotation_valid = true
          break
        case '2':
          this.edit3 = false
          this.edit2 = true
          this.e1 = 4
          break
        case '3':
          this.edit2 = false
          this.edit3 = true
          this.e1 = 5
          break
      }
      this.service_proposal = null
      this.contract = contract
      setTimeout(() => { this.dialog = true }, 100)
    },
    changeMode(operator){
      switch (operator) {
        case '+':
          if (this.e1 == 1) {
            this.generateQuoPDF()
          } else {
            this.e1 = Number(this.e1) + 1
          }
          break
        case '-':
          this.e1 = Number(this.e1) - 1
          break
      }
    },
    getSubscriberPersonType () {
      axios.get(this.$store.getters.getContracts(this.contract.id),{
          params: {
            'person_type': true,
          }
        })
        .then(response => {
          if (response.data) {
            if (response.data.person_type) {
              this.person_type = response.data.person_type
            }
            if (response.data.is_firm) {
              this.person_type = 'firm'
            }
          }
        })
    },
    getFiles (file_name) {
      axios.get(this.$store.getters.getSubscriberFilesLibrary(),{
          params: {
            'contract_id': this.contract.id,
            'name': file_name
          }
        })
        .then(response => {
          if (response.data && response.data.files && response.data.files.data && response.data.files.data.length > 0) {
            switch (file_name) {
              case 'service_proposal':
                this.proposal_file_exist = true
                this.file = response.data.files.data[0]
                break
              case 'price_quotation':
                this.quotation_file_exist = true
                this.file2 = response.data.files.data[0]
                break
            }
          }
        })
        .finally(() => {
          //
        })
    },
    validateFile (e) {
      if (e) {
        if (!['application/pdf'].includes(e.type)) {
          this.clearInput()
          this.emitNotification('error', 6000, 'error422.file_path_must_type')
        } else if (e.size > 4194304) {
          this.clearInput()
          this.emitNotification('error', 6000, 'error422.file_path_max_4096_kb')
        } else {
          this.service_proposal = e
        }
      }
    },
    clearInput () {
      this.$refs.proposal.reset()
      this.$refs.proposal.$refs.input.value = ''
      this.service_proposal = null
    },
    loadProposal () {
      this.loading = true
      let form_data = new FormData()
      let id = null
      form_data.append('subscriber_id', this.contract.subscriber_id)
      form_data.append('contract_id', this.contract.id)
      form_data.append('file_name', 'service_proposal')
      form_data.append('file_path', this.service_proposal)
      if (this.proposal_file_exist) {
        id = this.file.id
        form_data.append('_method', 'PATCH')
      }
      axios.post(this.$store.getters.getSubscriberFilesLibrary(id), form_data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.data.success) {
            this.clearInput()
            this.proposal_file_exist = true
            this.file = response.data.success
            this.emitNotification('success', 6000, 'success',
              {
                model: this.$tc('file_library.file',1),
                action: this.$tc('loaded',1)
              }, 1
            )
          } else {
            this.emitNotification('warning', 6000, 'base.something_wrong')
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
        })
    },
    generateQuoPDF () {
      this.loadingNext = true
      axios.get(this.$store.getters.getDocumentPDF(this.doc_id))
        .then(response => {
          if (response.data && response.data.success) {
            this.quotation_file_exist = true
            this.file2 = response.data.success
            this.loadingNext = false
            this.e1 = Number(this.e1) + 1
          }
        })
        .finally(() => {
          this.loadingNext = false
        })
    },
    sendAskingEmail () {
      this.loadingNext = true
      let action = new Array()
      action['text'] = 'Click Button';
      action['url'] = 'url';
      axios.post(this.$store.getters.sendAskingEmail, {
          'contract_id': this.contract.id,
          'types': this.contract_types_to_mail_proposal,
          'title': this.mail_title,
          'content': this.mail_content,
          'action': action,
          'files_ids': [this.file.id, this.file2.id],
          'greeting': 'Propuesta Comercial '+this.contract.legal_denomination+' ('+this.contract.doc+')'
        })
        .then(response => {
          if (response.data && response.data.success) {
            this.emitNotification('success', 6000, 'success',
              {
                model: this.$tc('base.email',1),
                action: this.$tc('sent',1)
              }, 1
            )
            this.loadingNext = false
            this.$store.dispatch('forceConfirmationFinish')
            this.dialog = false
          }
        })
        .finally(() => {
          this.loadingNext = false
        })
    },
    generateLetter(){
      this.loadingLetter = true
      this.letter.contract_id = this.contract.id
      axios.post(this.$store.getters.getAcceptanceLetter(), this.letter)
        .then(response =>{
          if (response.data.success) {
            this.letter_file_exist = true
            this.file3 = response.data.success
            this.acceptance_letter_info.file_id = response.data.success.id
          }
          if (response.data.letter_id) {
            this.acceptance_letter_info.letter_id = response.data.letter_id
          }
        })
        .finally(() => {
          this.loadingLetter = false
        })
    },
    sendLetter(){
      this.$store.dispatch('confirmationValue',{
        template: 'send_acceptance_letter',
        show: true,
        model: this.$tc('service_sale.acceptance_letter',1),
        title: this.$t('base.send'),
        pre: 'success',
        action: this.$tc('sent',2),
        content: this.$tc('base.confirm_send_acceptance_letter',1),
        type: 'info',
        confirm:{
          url: this.$store.getters.sendAcceptanceLetterPDF(this.acceptance_letter_info.letter_id),
          method: 'post',
          data: {
            'types': this.contract_types_to_mail_letter,
            'file_id': this.acceptance_letter_info.file_id
          }
        },
        pos: 2
      })
    },
    emitNotification (style, timeout, notifText, extra, pos) {
      this.$store.dispatch('pushNotification', {
        snackbar: true,
        style: style,
        mode: null,
        timeout: timeout,
        notifText: notifText,
        notifPos: pos,
        extra: extra
      })
    },
    getDoc(item){
      this.doc_id = item;
      this.loadingNext = false
    },
    errorDoc(item){
      this.dialog = false;
    },
    setValid(item){
      this.quotation_valid = item
    },
    documentationCompleted (val) {
      this.doc_completed = val
    },
    confirmDocsLoaded () {
      this.loadingNext = true
      axios.get(this.$store.getters.changeSubscriberCondition(this.contract.subscriber_id))
        .then(response => {
          if (response.data.success === false) {
            this.emitNotification('success', 4000, 'subscribers.condition_changed')
            this.dialog = false
            this.$emit('documentsLoaded')
          } else {
            this.emitNotification('warning', 6000, 'base.something_wrong')
          }
        })
        .finally(() => {
          this.loadingNext = false
        })
    }
  },
  watch: {
    dialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
      if (!val) {
        this.e1 = 1
        this.letter_file_exist = false
        this.proposal_file_exist = false
        this.quotation_file_exist = false
        this.file = null
        this.file2 = null
        this.file3 = null
        this.acceptance_letter_info = {
          letter_id: 0,
          file_id: 0
        }
        this.letter.considerations = ''
        this.person_type = null
        this.doc_completed = false
      }
    },
    e1 (val) {
      if (val == 2) {
        this.show_btn = false
        this.getFiles('service_proposal')
        this.getFiles('price_quotation')
      } else if (val == 5) {
        this.getSubscriberPersonType()
      } else {
        this.show_btn = true
      }
    }
  },
  computed: {
    showBtn () {
      return this.quotation_file_exist && this.proposal_file_exist ? true : this.show_btn
    }
  }
}
