var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('dialservicesale',{ref:"dialservicesale",on:{"documentsLoaded":function($event){return _vm.getContracts(false)}}}),_c('v-row',{staticClass:"py-5 px-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","color":"grey","icons-and-text":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[_vm._v(" "+_vm._s(_vm.$t('service_sale.awaiting'))+" "),_c('v-icon',[_vm._v("fas fa-user-clock")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v(" "+_vm._s(_vm.$t('service_sale.approve'))+" "),_c('v-icon',[_vm._v("fas fa-envelope-open-text")])],1),_c('v-tab',{attrs:{"href":"#tab-3"}},[_vm._v(" "+_vm._s(_vm.$t('service_sale.sent'))+" "),_c('v-icon',[_vm._v("fas fa-envelope")])],1),_c('v-tab-item',{attrs:{"value":_vm.step}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{staticClass:"pa-0 pt-10"},[_c('v-data-table',{staticClass:"elevation-0 contracts-table",attrs:{"headers":_vm.headers,"items":_vm.contracts,"mobile-breakpoint":0,"footer-props":{
                        'items-per-page-options': [10, 50, 100, 500, 1000]
                      },"options":_vm.options,"server-items-length":_vm.totalValues,"loading":_vm.tableLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pl-0",attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[(!_vm.expand)?_c('span',[_vm._v(" "+_vm._s(_vm.$tc('requests.requests',2))+" ")]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":function($event){_vm.expand = false}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-arrow-left")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('close')))])])],1),_c('div',{staticClass:"flex-grow-1"}),_c('v-expand-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],staticClass:"hidden-search",attrs:{"flat":"","height":"40","width":_vm.$vuetify.breakpoint.smAndDown ? '75%': _vm.$vuetify.breakpoint.lgAndUp ? '30%':'40%'}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-0 py-5",attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],ref:"search",attrs:{"dark":"","name":"filter","error-messages":_vm.errors.collect('filter'),"label":_vm.$t('base.search'),"single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1)],1)],1),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.expand},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","small":""},on:{"click":function($event){_vm.expand ? _vm.search():_vm.expandIt()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-search")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('base.search')))])])],1)]},proxy:true},{key:"item.id",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.id.toString().length < 5 ? ("00000" + item.id).slice(-5) : item.id)+" ")]}},{key:"item.action",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.policies.show),expression:"policies.show"}],class:_vm.$i18n.locale == 'es' ? 'mr-5':'mr-4',attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialservicesale.open(item,_vm.step[_vm.step.length-1])}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.step == 'tab-1' ? 'fas fa-file-contract' : _vm.step == 'tab-2' ? 'fas fa-envelope-open-text' : 'fas fa-file-upload')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.step == 'tab-1' ? _vm.$t('base.view_details') : _vm.step == 'tab-2' ? ((_vm.$t('base.send')) + " " + (_vm.$t('service_sale.acceptance_letter'))) : _vm.$i18n.locale == 'es' ? ((_vm.$tc('load',1)) + " " + (_vm.$t('subscribers.documentation')) + " " + (_vm.$t('of2')) + " " + (_vm.$tc('subscribers.subscribers',1))) : ((_vm.$tc('load',1)) + " " + (_vm.$tc('subscribers.subscribers',1)) + "'s " + (_vm.$t('subscribers.documentation')) + " "))+" ")])])]}}],null,true)})],1)],1)],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }